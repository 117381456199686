/* WordSequence.module.css */
.wordSequence {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
}

.sequenceWord {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.06em;
  text-align: center;
  position: absolute;
  white-space: nowrap;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  user-select: none;
}

@media (max-width: 768px) {
  .sequenceWord {
    font-size: 1rem;
  }

  .wordSequence {
    /* Ensure it doesn't interfere with mobile navigation */
    z-index: 5;
  }
}