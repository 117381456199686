/* Home.css */
body,
html {
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_div_home {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacity;
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* Keeps video in the background */
}

.content-container {
  position: relative;
  z-index: 1;
  /* Ensures content stays above video */
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.main_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.left_section,
.right_section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.left_section {
  width: 60% !important;
}

.right_section {
  width: 40%;
  color: black;
}

.headline {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: rgba(180, 191, 207, 0.518);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.custom-btn::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-btn:hover {
  background-color: rgba(180, 191, 207, 0.4);
  color: white;
  text-decoration: none;
  transform: translateY(-2px);
}

.custom-btn:hover::after {
  width: 100%;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .main_div_home {
    height: 100vh;
  }

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }

  .main_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;
  }

  .left_section,
  .right_section {
    width: 100% !important;
    height: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headline {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .custom-btn {
    display: inline-block;
    width: auto;
    min-width: 200px;
    max-width: 80%;
    text-align: center;
    padding: 0.7rem 1.25rem;
    font-size: 0.95rem;
  }
}