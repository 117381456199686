.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Changed from 85vh to 100vh */
  width: 100%;
  /* Changed from 100vw to prevent horizontal scrollbar */
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./abstract2.jpg');
  background-size: cover;
  background-position: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
}

.contact-text {
  margin-bottom: 20px;
  font-size: 18px;
  color: #ffffff;
}

.linkedin-link {
  display: inline-block;
  transition: transform 0.3s ease;
  background: transparent;
}

.linkedin-link:hover {
  transform: scale(1.1);
}

.linkedin-logo {
  width: 25px;
  height: 25px;
  /* Removed the filter to keep LinkedIn logo in its original color */
}

/* Add these global styles to remove any potential margins or padding */
.dark-bg-page {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #000000;
  overflow: hidden;
  /* Prevents scrolling */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #000000;
}