/* App.css */

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./assets/fonts/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./assets/fonts/IBMPlexSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

.app-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

footer {
    flex-shrink: 0;
}