/* src/Screens/Products.module.css */
.mainContainer {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./abstract2.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  margin: 0;
  padding: 0;
}

.productSection {
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  /* More balanced ratio */
  gap: 4rem;
  align-items: center;
  height: calc(100vh - 4rem);
  padding: 60px 2rem 2rem 2rem;
}

.productInfo {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-width: 350px;
  /* Slightly wider minimum */
}

.productLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
}

.productInfo h1 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 150;
}

.productInfo p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}

.exploreButton {
  display: block;
  /* Changed from inline-block to block */
  width: fit-content;
  /* Added to ensure button width matches content */
  margin: 2rem auto;
  /* Added auto margins for horizontal centering */
  padding: 0.75rem 1.5rem;
  background-color: rgba(180, 191, 207, 0.518);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.exploreButton:hover {
  background-color: rgba(180, 191, 207, 0.4);
  color: white;
  text-decoration: none;
}

.previewSection {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  max-height: 70vh;
  /* Slightly reduced maximum height */
}

.carouselContainer {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
  image-rendering: -webkit-optimize-contrast;
}

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.carouselButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.carouselButton:first-of-type {
  left: 1rem;
}

.carouselButton:last-of-type {
  right: 1rem;
}

@media (max-width: 1024px) {
  .productSection {
    grid-template-columns: 1fr;
    gap: 2rem;
    height: auto;
    padding: 2rem 0;
  }
}