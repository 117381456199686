/* Base navbar styles */
.navbar-custom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    backdrop-filter: blur(8px);
    font-size: 0.88rem;
    height: 60px;
    border: none !important;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.904) !important;
}

/* Navigation links base styles */
.nav-link-custom {
    color: rgba(255, 255, 255, 0.85) !important;
    transition: all 0.3s ease !important;
    position: relative;
}


/* Light navbar styles - make these more specific */
.navbar-custom.navbar-inverted {
    background-color: rgba(255, 255, 255, 0.95) !important;
}


/* Hover and active states for dark navbar */
.nav-link-custom:hover,
.nav-link-custom.active {
    color: #ffffff !important;
    transform: translateY(-1px);
}

.navbar-custom .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.7);
}

.navbar-custom .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.7)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Styles for light navbar */
.navbar-inverted .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.7);
}

.navbar-inverted .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.7)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Optional: Add hover states */
.navbar-custom .navbar-toggler:hover {
    border-color: rgba(255, 255, 255, 0.9);
}

.navbar-inverted .navbar-toggler:hover {
    border-color: rgba(0, 0, 0, 0.9);
}

/* Underline animation for links */
.nav-link-custom::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 50%;
    background-color: white;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.nav-link-custom:hover::after {
    width: 100%;
}

/* Light navbar styles */
.navbar-inverted {
    background-color: rgba(255, 255, 255, 0.85) !important;
}

.navbar-inverted .nav-link-custom {
    color: rgba(0, 0, 0, 0.7) !important;
}

.navbar-inverted .nav-link-custom:hover,
.navbar-inverted .nav-link-custom.active {
    color: rgba(0, 0, 0, 1) !important;
    transform: translateY(-1px);
}

.navbar-inverted .nav-link-custom::after {
    background-color: black;
}

.navbar-inverted .navbar-logo {
    filter: invert(1);
}

/* Other navbar styles */
.navbar {
    border: none !important;
    box-shadow: none !important;
}

.navbar::after {
    display: none !important;
}

.navbar-container {
    height: 100%;
}

.navbar-logo {
    height: 32px;
}

/* Mobile specific styles */
@media (max-width: 896px) {

    /* Stronger background for mobile */
    .navbar-custom {
        background-color: rgba(0, 0, 0, 0.95) !important;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    /* Mobile menu dropdown styles */
    .navbar-collapse {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.95);
        padding: 1rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    /* Mobile menu links */
    .navbar-nav {
        padding: 0.5rem 0;
    }

    .nav-link-custom {
        padding: 0.75rem 1rem !important;
        margin: 0.25rem 0;
    }
}

/* Fix for iOS specific backdrop-filter */
@supports (-webkit-touch-callout: none) {
    .navbar-custom {
        background-color: rgba(0, 0, 0, 0.98) !important;
    }
}


@media (max-width: 896px) {
    .navbar-custom.navbar-inverted {
        background-color: rgba(255, 255, 255, 0.95) !important;
    }

    .navbar-custom.navbar-inverted .navbar-collapse {
        background-color: rgba(255, 255, 255, 0.95) !important;
    }
}