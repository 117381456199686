.main {
    max-width: 800px;
    margin: 0 auto;
    padding: 120px 20px 40px 20px;
    color: #333;
    line-height: 1.6;
}

.h1 {
    margin-top: 0;
    /* This ensures the h1 doesn't add extra space at the top */
    margin-bottom: 30px;
}

.heroImageContainer {
    width: 100%;
    margin-bottom: 2em;
    background-color: #f0f0f0;
    /* Light grey background */
    border-radius: 8px;
    /* Match the image's border-radius */
    overflow: hidden;
    /* Ensures the image doesn't overflow the rounded corners */
}

.heroImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
    /* This adds rounded corners */
}

.tableOfContents {
    margin-bottom: 40px;
}

.tableOfContents ul {
    list-style-type: none;
    padding-left: 0;
}

.tableOfContents li {
    margin-bottom: 10px;
}

.tableOfContents a,
.section a:not(.titleLink) {
    color: #0070c9;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.tableOfContents a::after,
.section a:not(.titleLink)::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #0070c9;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.tableOfContents a:hover::after,
.section a:not(.titleLink):hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.section {
    margin-bottom: 60px;
}

.h2 {
    font-size: 32px;
    font-weight: semibold;
    margin-bottom: 20px;
}

.h3 {
    font-size: 24px;
    font-weight: semibold;
    margin-bottom: 15px;
}

.p {
    margin-bottom: 20px;
}

.ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

.articleContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

.articleImage {
    width: 200px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
}

.articleContent {
    flex: 1;
}

.titleLink {
    color: inherit;
    text-decoration: none;
}

.titleLink:hover {
    text-decoration: underline;
}

.authorInfo {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.authorImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.date {
    margin-left: auto;
    color: #666;
}

@media (max-width: 600px) {
    .h2 {
        font-size: 28px;
    }

    .h3 {
        font-size: 22px;
    }

    .articleContainer {
        flex-direction: column;
    }

    .articleImage {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
}